import { EGroupPartition } from 'api/groups/types';

import { GroupsRequestSort } from 'settings/consts';

export type MembersLabelMap = {
  Members: string;
  Students: string;
  Coworkers: string;
  Friends: string;
  Gamers: string;
  Custom: string;
};

export const MEMBER_LABELS: MembersLabelMap = {
  Members: 'groups-web.members_icu',
  Students: 'groups-web.members.students_icu',
  Coworkers: 'groups-web.members.coworkers_icu',
  Friends: 'groups-web.members.friends_icu',
  Gamers: 'groups-web.members.gamers_icu',
  Custom: 'groups-web.members.custom',
};

export const SEE_ALL_MEMBER_LABELS: MembersLabelMap = {
  Members: 'groups-web.members.discussion.members.see-all',
  Students: 'groups-web.members.students.discussion.members.see-all',
  Coworkers: 'groups-web.members.coworkers.discussion.members.see-all',
  Friends: 'groups-web.members.friends.discussion.members.see-all',
  Gamers: 'groups-web.members.gamers.discussion.members.see-all',
  Custom: 'groups-web.members.custom.discussion.members.see-all',
};

export const MEMBERS_ADD_WIDGET_LABELS: MembersLabelMap = {
  Members: 'groups-web.members.add-widget.title',
  Students: 'groups-web.members.students.add-widget.title',
  Coworkers: 'groups-web.members.coworkers.add-widget.title',
  Friends: 'groups-web.members.friends.add-widget.title',
  Gamers: 'groups-web.members.gamers.add-widget.title',
  Custom: 'groups-web.members.custom.add-widget.title',
};

export const MEMBERS_WITH_COUNT_LABELS: MembersLabelMap = {
  Members: 'groups-web.members.count_icu',
  Students: 'groups-web.members.students.count_icu',
  Coworkers: 'groups-web.members.coworkers.count_icu',
  Friends: 'groups-web.members.friends.count_icu',
  Gamers: 'groups-web.members.gamers.count_icu',
  Custom: 'groups-web.members.custom.count_icu',
};

export const ESortOptions: { [key in GroupsRequestSort]: string } = {
  [GroupsRequestSort.NAME]: 'lowerCaseTitle',
  [GroupsRequestSort.MEMBERS_COUNT]: 'membersCount',
  [GroupsRequestSort.RECENT_ACTIVITY]: 'recentActivityDate',
};

export const EGroupsNamespace = {
  ...EGroupPartition,
  RECOMMENDED_DIALOG: 'recommended-dialog',
  SEARCH: 'search',
} as const;

export type EGroupsNamespaceType =
  (typeof EGroupsNamespace)[keyof typeof EGroupsNamespace];
